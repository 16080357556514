import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import View from "./View";
import Movie from "./Movie";
import PM from "./PM";
import SpecialView from "./SpecialView";

const root = ReactDOM.createRoot(document.getElementById('root'));
const router = createBrowserRouter([
    {
        path: "/view",
        element: <View/>,
    },
    {
        path: "/view2",
        element: <SpecialView/>,
    },

    {
        path: "/:topic",
        element:<PM/> ,
    },
    {
        path: "/:topic/:subTopic",
        element:<PM/> ,
    },
    {
        path: "/movie/:movieId/",
        element:<Movie/> ,
    },
    {
        path: "/movie/:movieId/:episode",
        element:<Movie/> ,
    },
    {
        path: "/phim/:movieId/:movieName",
        element:<Movie/> ,
    },
    {
        path: "/phim/:movieId/:movieName/:episode",
        element:<Movie/> ,
    },
    {
        path: "/",
        element: <PM/>,
    },
]);

root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
