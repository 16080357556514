import React, {useEffect, useRef, useState} from 'react';
import ReactHlsPlayer from "react-hls-player";
import {useSearchParams} from "react-router-dom";
import {ReactFlvPlayer} from 'self-flv-player'
import {Flex} from "antd";

const STREAM_SERVER = window._env_.STREAM_SERVER


const View = () => {
    const [params] = useSearchParams();
    const url = params.get("url");
    const referer = params.get("referer");
    const [src, setSrc] = useState("")


    useEffect(() => {
        console.log("url",url)
        setSrc(url)
    }, [url]);
    const playerRef = useRef();

    return (
        <Flex style={{backgroundColor:"black",height:"100vh"}} align={"center"}>
            <ReactHlsPlayer
                id={"reactPlayer"}
                playerRef={playerRef}
                src={`${STREAM_SERVER}/${url.endsWith(".m3u8")?'proxy-playlist':'extract-playlist'}?url=${src}${referer ? '&referer=' + referer : ''}`}
                autoPlay={false}
                controls={true}

                onPlay={() => {
                    // playerRef.current.requestFullscreen()
                }}
                hlsConfig={{maxBufferLength: 180}}
                width="100%"
                style={{height:'100%'}}
            />
        </Flex>
    );
};

export default View;
